// here card styling begins
// todo please change the .gridd class to a more descriptive name
// Select all elements with the class 'card' that are children of the '.gridd' element

document.querySelectorAll('.gridd>.card').forEach((boxes) => {
  // Add a 'mousemove' event listener to the window
  window.addEventListener('mousemove', (e) => {
    // Get the bounding rectangle of the current 'card' element
    let _rect = boxes.getBoundingClientRect();
    // Calculate the x position of the mouse relative to the 'card' element
    let x = e.clientX - _rect.left;
    // Calculate the y position of the mouse relative to the 'card' element
    let y = e.clientY - _rect.top;
    // Dynamically update the 'card' element's border image to a radial gradient
    // centered at the mouse position, transitioning from a specific color to transparent
    boxes.style.borderImage = getBlueEdgeCardProps(x, y);
  });

  function getBlueEdgeCardProps(x, y) {
    const opacity = '0.99';
    const transparency = '40%';
    const rgba = `rgba(69, 115, 255, ${opacity})`;
    const borderTickness = '4px';
    const borderImageOutset = '0px';

    return `radial-gradient(40% 75% at ${x}px ${y}px,
       ${rgba},
       transparent ${transparency}) 
       1 / 4px / 0px stretch
    `;
  }

  // Add a 'mousemove' event listener specifically to each 'card' element
  boxes.addEventListener('mousemove', (e) => {
    // Again, get the bounding rectangle of the 'card' element
    let _rect = boxes.getBoundingClientRect();
    // Calculate the x and y positions of the mouse within the 'card'
    let x = e.clientX - _rect.left;
    let y = e.clientY - _rect.top;
    // Set a constant for the transparency level used in the gradient
    const transparency = '40%';
    const opacity = '0.20';
    // Dynamically update the 'card' element's background to a radial gradient
    // centered at the mouse position, with a very large size to create a spotlight effect
    // and transitioning to transparent
    boxes.style.background = `radial-gradient(1200px circle at ${x}px ${y}px , rgba(255,255,255,${opacity}),transparent ${transparency})`;
  });

  // Add a 'mouseleave' event listener to each 'card' element
  boxes.addEventListener('mouseleave', (e) => {
    // Reset the background of the 'card' to transparent when the mouse leaves its area
    e.target.style.background = `transparent`;
  });
});
